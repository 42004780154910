.privacy-page {
  min-height: 100vh;
  background: #0a0a0a;
  color: #ffffff;
  padding: 2rem 0;
  position: relative;
  overflow-x: hidden;
}

.animated-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, #1a1a1a, #2d2d2d);
  z-index: -1;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, rgba(255, 215, 0, 0.15), transparent 60%);
  animation: pulse 4s infinite;
}

.privacy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 215, 0, 0.2);
  position: relative;
  z-index: 1;
}

.privacy-container h1 {
  color: #ffd700;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.last-updated {
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 2rem;
  font-style: italic;
}

.policy-section {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.policy-section:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.policy-section h2 {
  color: #ffd700;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.policy-section p {
  line-height: 1.6;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.9);
}

@media (max-width: 768px) {
  .privacy-container {
    padding: 1.5rem;
    margin: 1rem;
  }

  .privacy-container h1 {
    font-size: 2rem;
  }

  .policy-section h2 {
    font-size: 1.5rem;
  }
}

@keyframes pulse {
  0%, 100% { opacity: 0.5; }
  50% { opacity: 0.8; }
} 