html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  background: #0a0a0a;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.signup-container {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(10, 10, 10, 0.7);
  color: white;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  backdrop-filter: blur(10px);
}

.animated-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  background: linear-gradient(135deg, 
    rgba(10, 10, 10, 0.95),
    rgba(30, 30, 30, 0.95)
  );
}

.gradient-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, 
    rgba(255, 215, 0, 0.15), 
    rgba(30, 144, 255, 0.15)
  );
  animation: gradientShift 10s ease infinite;
}

.signup-content {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 1200px;
  text-align: center;
  animation: fadeIn 2s ease-in-out;
  padding: 3rem;
  background: rgba(17, 24, 39, 0.6);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 215, 0, 0.2);
}

.signup-header {
  margin-bottom: 3rem;
}

.logo-container {
  margin-bottom: 1rem;
}

.logo-image {
  width: 80px;
  height: auto;
}

h1 {
  font-size: 2rem;
  font-weight: 800;
  color: #ffd700;
  margin: 1rem 0;
}

.tagline {
  font-size: 2rem;
  margin: 0;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  letter-spacing: 1px;
}

.highlight {
  font-size: 3.5rem;
  color: #ffd700;
  margin: 0.5rem 0;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1.2;
  text-shadow: 0 0 20px rgba(255, 215, 0, 0.3);
}

.subtitle {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 1rem;
  line-height: 1.6;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin: 4rem auto;
  max-width: 1000px;
  perspective: 1000px;
}

.feature-card {
  background: rgba(17, 24, 39, 0.6);
  border: 1px solid rgba(255, 215, 0, 0.2);
  border-radius: 16px;
  padding: 2.5rem 2rem;
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(5px);
}

.feature-card:hover {
  transform: translateY(-5px);
  border-color: rgba(255, 215, 0, 0.4);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  background: rgba(17, 24, 39, 0.8);
}

.feature-icon {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  display: inline-block;
  transition: transform 0.3s ease;
  background: transparent;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  background-clip: unset;
}

.feature-card:hover .feature-icon {
  transform: scale(1.1);
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.3));
}

.feature-icon.workout {
  color: #ffffff;
}

.feature-icon.medal {
  color: #ffd700;
}

.feature-icon.strength {
  color: #ffffff;
}

.feature-icon.fire {
  color: #ff4d4d;
}

.feature-icon.levels {
  font-size: 2rem;
  color: #ffd700;
}

.geometric-shapes .shape {
  opacity: 0.15;
  filter: blur(2px);
}

.submit-button {
  width: 100%;
  padding: 1.2rem 1.5rem;
  background: #ffd700;
  color: #0a0a0a;
  border: none;
  border-radius: 12px;
  font-weight: 700;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 2px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.submit-button:hover {
  transform: translateY(-2px);
  background: #ffe44d;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.email-input {
  width: 100%;
  padding: 1.2rem 1.5rem;
  background: rgba(255, 255, 255, 0.07);
  border: 2px solid rgba(255, 215, 0, 0.3);
  border-radius: 12px;
  color: white;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  margin-bottom: 1rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.email-input:focus {
  border-color: #ffd700;
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.2);
  outline: none;
  transform: translateY(-2px);
}

.success-message {
  animation: slideIn 0.5s ease-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.logo-image {
  animation: pulse 2s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.tagline .highlight {
  position: relative;
  display: inline-block;
  animation: glow 2s ease-in-out infinite;
}

@keyframes glow {
  0%, 100% {
    text-shadow: 0 0 5px rgba(255, 215, 0, 0.5);
  }
  50% {
    text-shadow: 0 0 20px rgba(255, 215, 0, 0.8);
  }
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.signup-content {
  animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.progress-bar {
  width: 0%;
  height: 3px;
  background: #ffd700;
  animation: load 2s forwards;
}

@keyframes load {
  to {
    width: 100%;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes float {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) rotate(180deg);
  }
  100% {
    transform: translateY(0px) rotate(360deg);
  }
}

@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  
  .feature-card {
    padding: 1.5rem;
  }
  
  .signup-content {
    padding: 1rem;
  }
}

.signup-form {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.email-input {
  width: 100%;
  padding: 1rem 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 215, 0, 0.3);
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.email-input:focus {
  border-color: #ffd700;
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.2);
  outline: none;
}

.submit-button {
  width: 100%;
  padding: 1rem 1.5rem;
  background: #ffd700;
  color: #0a0a0a;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.submit-button:hover {
  transform: translateY(-2px);
  background: #ffe44d;
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.signup-note {
  margin-top: 1rem;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 0.9rem;
}

.privacy-note {
  margin-top: 0.5rem;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: 0.8rem;
}

@media (max-width: 768px) {
  .signup-form {
    padding: 0 1rem;
  }
  
  .email-input,
  .submit-button {
    padding: 0.875rem 1.25rem;
  }
}

.tagline-container {
  margin: 1rem 0;
  line-height: 1.2;
}

.geometric-shapes {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
}

.shape {
  position: absolute;
  border-radius: 50%;
  opacity: 0.15;
  animation: float 20s infinite ease-in-out;
}

.shape-1 {
  width: 400px;
  height: 400px;
  background: linear-gradient(45deg, #ffd700, #ff6b6b);
  top: -200px;
  left: -200px;
}

.shape-2 {
  width: 500px;
  height: 500px;
  background: linear-gradient(45deg, #4f46e5, #06b6d4);
  bottom: -250px;
  right: -250px;
  animation-delay: -5s;
}

.shape-3 {
  width: 300px;
  height: 300px;
  background: linear-gradient(45deg, #10b981, #3b82f6);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation-delay: -10s;
}

@keyframes float {
  0%, 100% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(20px, -20px) rotate(180deg);
  }
}

.urgent-countdown {
  color: #ff3333 !important;
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.countdown-container {
  transition: color 0.3s ease;
  font-weight: bold;
  font-size: 1.5rem;
  margin: 1rem 0;
}

.countdown-value {
  font-size: 2rem;
  font-weight: 800;
}

.countdown-label {
  font-size: 1.2rem;
  opacity: 0.9;
}

.cta-section {
  background: rgba(0, 0, 0, 0.2);
  padding: 2rem;
  border-radius: 16px;
  margin: 2rem 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.feature-card h3 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  color: #ffd700;
}

.feature-card p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
}

.success-container {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 2rem;
  text-align: center;
  animation: fadeIn 0.5s ease-out;
}

.success-animation {
  margin: 2rem auto;
}

.checkmark {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #4CAF50;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: white;
  margin: 0 auto;
  animation: scaleIn 0.5s ease-out;
}

.welcome-message {
  color: #ffd700;
  font-size: 2rem;
  margin: 1.5rem 0;
  animation: slideUp 0.5s ease-out;
}

.next-steps {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin: 2rem 0;
}

.step {
  background: rgba(255, 255, 255, 0.05);
  padding: 1.5rem;
  border-radius: 12px;
  transition: transform 0.3s ease;
}

.step:hover {
  transform: translateY(-5px);
}

.step-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.success-countdown {
  margin: 2rem 0;
}

.social-share {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.share-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
}

.share-button {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
}

.share-button.twitter {
  background: #1DA1F2;
  color: white;
}

.share-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  from { transform: scale(0); }
  to { transform: scale(1); }
}

@keyframes slideUp {
  from { 
    opacity: 0;
    transform: translateY(20px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .next-steps {
    grid-template-columns: 1fr;
  }
  
  .welcome-message {
    font-size: 1.5rem;
  }
}

.current-challenge-section {
  margin: 3rem auto;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  border: 1px solid rgba(255, 215, 0, 0.2);
  max-width: 1000px;
}

.current-challenge-section h2 {
  color: #ffd700;
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.current-challenge-section h3 {
  color: white;
  font-size: 1.5rem;
  margin: 1rem 0;
  text-align: center;
}

.challenge-image-wrapper {
  width: 100%;
  max-width: 500px;
  margin: 0 auto 2rem;
  border-radius: 12px;
  overflow: hidden;
}

.challenge-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 12px;
  transition: transform 0.3s ease;
}

.challenge-image:hover {
  transform: scale(1.02);
}

.challenge-levels {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.challenge-level {
  background: rgba(0, 0, 0, 0.2);
  padding: 1.5rem;
  border-radius: 12px;
  text-align: center;
  transition: transform 0.3s ease;
}

.challenge-level:hover {
  transform: translateY(-5px);
}

.challenge-level h4 {
  color: #ffd700;
  margin: 1rem 0;
  font-size: 1.2rem;
}

.challenge-level p {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
}

.badge-preview {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.badge-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(0 0 10px rgba(255, 215, 0, 0.3));
}

.challenge-level.bronze { border: 1px solid #cd7f32; }
.challenge-level.silver { border: 1px solid #c0c0c0; }
.challenge-level.gold { border: 1px solid #ffd700; }

.loading-spinner {
  color: #ffd700;
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
}

.current-challenge-section.loading {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Add pulse animation for loading state */
@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

.loading-spinner {
  animation: pulse 1.5s infinite ease-in-out;
}

.current-challenge-section.error {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.current-challenge-section .error-message {
  color: #ff6b6b;
  text-align: center;
  padding: 2rem;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .challenge-levels {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .current-challenge-section {
    padding: 1rem;
    margin: 2rem auto;
  }

  .challenge-image-wrapper {
    max-width: 100%;
  }

  .current-challenge-section h2 {
    font-size: 1.5rem;
  }

  .current-challenge-section h3 {
    font-size: 1.2rem;
  }

  .challenge-level h4 {
    font-size: 1.1rem;
  }
}

.badge-vault-preview {
  width: 100%;
  padding: 4rem 2rem;
  margin: 2rem 0;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  border: 1px solid rgba(255, 215, 0, 0.2);
  text-align: center;
}

.badge-vault-preview .section-title {
  font-size: 2.5rem;
  color: #ffd700;
  text-align: center;
  margin-bottom: 3rem;
  text-shadow: 0 0 20px rgba(255, 215, 0, 0.3);
}

.badge-vault-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.badge-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  justify-items: center;
  margin-bottom: 2rem;
}

.badge-preview-card {
  position: relative;
  padding: 1.5rem;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}

.badge-preview-card:hover {
  transform: translateY(-10px) rotateY(10deg);
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.8), 0 0 40px rgba(255, 215, 0, 0.6);
}

.badge-glow {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150%;
  height: 150%;
  transform: translate(-50%, -50%);
  background: radial-gradient(circle, rgba(255, 215, 0, 0.2) 0%, rgba(255, 215, 0, 0) 70%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.badge-preview-card:hover .badge-glow {
  opacity: 1;
}

.badge-preview-card img {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  filter: drop-shadow(0 0 10px rgba(255, 215, 0, 0.3));
}

.badge-preview-card span {
  display: block;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 500;
}

.badge-preview-card.gold { border: 2px solid #ffd700; }
.badge-preview-card.silver { border: 2px solid #c0c0c0; }
.badge-preview-card.bronze { border: 2px solid #cd7f32; }

.badge-vault-right {
  text-align: center;
  padding: 2rem;
}

.badge-vault-right h3 {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 1.5rem;
}

.badge-vault-right p {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  margin-bottom: 2rem;
}

.badge-features {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto;
}

.badge-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.9);
  flex: 1;
  min-width: 150px;
}

.feature-icon {
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .badge-vault-content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .badge-vault-preview {
    padding: 2rem 1rem;
  }

  .badge-vault-preview .section-title {
    font-size: 2rem;
  }

  .badge-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }

  .badge-preview-card {
    padding: 1rem;
  }
}
