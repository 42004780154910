.challenge-manager {
  padding: 20px;
  background: #1a1a1a;
  border-radius: 8px;
  margin: 20px;
}

.difficulty-section {
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #333;
  border-radius: 8px;
}

.difficulty-section h3 {
  color: #ffd700;
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #ccc;
}

.form-group input[type="text"],
.form-group input[type="datetime-local"],
.form-group textarea {
  width: 100%;
  padding: 8px;
  background: #2a2a2a;
  border: 1px solid #444;
  border-radius: 4px;
  color: white;
}

.form-group textarea {
  min-height: 100px;
  resize: vertical;
}

button {
  background: #ffd700;
  color: #1a1a1a;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.error {
  color: #ff4444;
  margin: 10px 0;
}

.success {
  color: #44ff44;
  margin: 10px 0;
}

.form-group small {
  color: #666;
  font-size: 0.8rem;
  margin-top: 0.25rem;
  display: block;
}

/* Optional: Add a preview for the challenge image */
.challenge-image-preview {
  max-width: 200px;
  margin: 1rem 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
} 