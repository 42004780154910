.error-boundary {
  padding: 2rem;
  text-align: center;
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.3);
  border-radius: 8px;
  margin: 1rem;
}

.error-boundary h2 {
  color: #ff4444;
  margin-bottom: 1rem;
}

.error-boundary button {
  background: #ff4444;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.error-boundary button:hover {
  background: #ff6666;
  transform: translateY(-2px);
}

.error-boundary details {
  margin-top: 1rem;
  text-align: left;
  color: #666;
  font-family: monospace;
  font-size: 0.9rem;
} 