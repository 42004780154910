.countdown-timer {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  padding: 15px;
  text-align: center;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.countdown-header {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #fff;
}

.timer-display {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.time-segment {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 60px;
}

.time-value {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
}

.time-label {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 5px;
}

.time-separator {
  font-size: 2rem;
  color: #fff;
  margin: 0 5px;
  line-height: 1;
  align-self: flex-start;
  padding-top: 5px;
}

/* Mobile responsiveness */
@media (max-width: 480px) {
  .countdown-timer {
    padding: 10px;
    margin: 10px auto;
  }

  .timer-display {
    gap: 5px;
  }

  .time-segment {
    min-width: 45px;
  }

  .time-value {
    font-size: 1.5rem;
  }

  .time-label {
    font-size: 0.7rem;
  }

  .time-separator {
    font-size: 1.5rem;
    margin: 0 2px;
  }
}

/* Extra small devices */
@media (max-width: 320px) {
  .time-segment {
    min-width: 40px;
  }

  .time-value {
    font-size: 1.2rem;
  }

  .time-separator {
    font-size: 1.2rem;
  }
}







/* Add urgent styling */

.urgent {

  animation: pulseGlow 1s ease-in-out infinite;

}



@keyframes pulseGlow {

  0%, 100% {

    transform: scale(1);

    box-shadow: 0 0 20px rgba(255, 215, 0, 0.5);

    color: #ffd700;

  }

  50% {

    transform: scale(1.05);

    box-shadow: 0 0 30px rgba(255, 0, 0, 0.8);

    color: #ff3333;

  }

} 







/* Add this to your existing styles */



.countdown-header {



  color: rgba(255, 255, 255, 0.9);



  font-size: 1.2rem;



  margin-bottom: 1rem;



  font-weight: 600;



}






























































































































