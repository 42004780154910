.claim-page {
  min-height: 100vh;
  background: #111827;
  color: white;
  position: relative;
  overflow: hidden;
}

.animated-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, rgba(17, 24, 39, 0.8) 0%, #111827 70%);
  z-index: 1;
}

.geometric-shapes {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.shape {
  position: absolute;
  border-radius: 50%;
  opacity: 0.1;
  animation: float 20s infinite ease-in-out;
}

.shape-1 {
  width: 300px;
  height: 300px;
  background: linear-gradient(45deg, #ffd700, #ff6b6b);
  top: -150px;
  left: -150px;
  animation-delay: -5s;
}

.shape-2 {
  width: 400px;
  height: 400px;
  background: linear-gradient(45deg, #4f46e5, #06b6d4);
  bottom: -200px;
  right: -200px;
  animation-delay: -10s;
}

.shape-3 {
  width: 200px;
  height: 200px;
  background: linear-gradient(45deg, #10b981, #3b82f6);
  top: 50%;
  left: 50%;
  animation-delay: -15s;
}

@keyframes float {
  0%, 100% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(50px, 50px) rotate(90deg);
  }
  50% {
    transform: translate(0, 100px) rotate(180deg);
  }
  75% {
    transform: translate(-50px, 50px) rotate(270deg);
  }
}

.claim-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  position: relative;
  z-index: 2;
}

.badge-showcase {
  text-align: center;
  margin-bottom: 3rem;
}

.celebration-title {
  font-size: 3rem;
  font-weight: bold;
  background: linear-gradient(45deg, #ffd700, #ff6b6b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2rem;
  animation: titlePop 0.5s ease-out;
}

@keyframes titlePop {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.badge-wrapper {
  position: relative;
  display: inline-block;
  margin: 2rem 0;
}

.badge-image {
  width: 200px;
  height: 200px;
  object-fit: contain;
  animation: badgeReveal 1s ease-out;
  position: relative;
  z-index: 2;
}

.badge-glow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 220px;
  height: 220px;
  background: radial-gradient(circle, rgba(255,215,0,0.2) 0%, rgba(255,215,0,0) 70%);
  animation: glow 2s infinite ease-in-out;
  z-index: 1;
}

@keyframes badgeReveal {
  0% {
    transform: scale(0) rotate(-180deg);
    opacity: 0;
  }
  100% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
}

@keyframes glow {
  0%, 100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.5;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 0.8;
  }
}

.achievement-text {
  font-size: 1.5rem;
  margin: 1rem 0;
  color: #ffd700;
  animation: fadeIn 1s ease-out 0.5s both;
}

.badge-description {
  font-size: 1.1rem;
  color: #9ca3af;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
  animation: fadeIn 1s ease-out 0.7s both;
}

.user-progress {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1rem;
  padding: 2rem;
  margin-bottom: 2rem;
  animation: slideUp 0.5s ease-out 1s both;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.medals-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.stat-card, .medal-stat {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  padding: 1rem;
  text-align: center;
  transition: transform 0.2s;
}

.stat-card:hover, .medal-stat:hover {
  transform: translateY(-5px);
}

.stat-icon, .medal-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  display: block;
}

.stat-value, .medal-count {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0.5rem 0;
  display: block;
}

.stat-label, .medal-label {
  font-size: 0.9rem;
  color: #9ca3af;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem 0;
  animation: fadeIn 1s ease-out 1.5s both;
}

.primary-button {
  background: #4f46e5;
  color: white;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  border: none;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.primary-button:hover {
  transform: translateY(-2px);
  background: #4338ca;
}

.share-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.share-button {
  padding: 0.8rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.2s;
}

.twitter { background: #1DA1F2; color: white; }
.facebook { background: #4267B2; color: white; }
.linkedin { background: #0077B5; color: white; }

.share-button:hover {
  transform: translateY(-2px);
  filter: brightness(110%);
}

.next-challenge {
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  animation: fadeIn 1s ease-out 2s both;
}

.next-challenge h2 {
  color: #ffd700;
  margin-bottom: 0.5rem;
}

.next-challenge p {
  color: #9ca3af;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 640px) {
  .stats-grid {
    grid-template-columns: 1fr;
  }
  
  .medals-grid {
    grid-template-columns: 1fr;
  }
  
  .share-buttons {
    grid-template-columns: 1fr;
  }
  
  .claim-container {
    padding: 1rem;
  }
  
  .badge-image {
    width: 120px;
    height: 120px;
  }
  
  .celebration-title {
    font-size: 2rem;
  }
}

.loading-state,
.error-state {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  margin: 2rem auto;
  max-width: 600px;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top-color: #ffd700;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

.error-state h2 {
  color: #ff4d4d;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.error-state p {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 2rem;
  line-height: 1.6;
  font-size: 1.1rem;
}

.primary-button {
  background: #4f46e5;
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-block;
}

.primary-button:hover {
  transform: translateY(-2px);
  background: #4338ca;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

@keyframes spin {
  to { transform: rotate(360deg); }
}


