.test-email {
  padding: 20px;
  background: #1a1a1a;
  border-radius: 8px;
  margin: 20px;
}

.test-email h2 {
  color: #ffd700;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #ccc;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  background: #2a2a2a;
  border: 1px solid #444;
  border-radius: 4px;
  color: white;
}

.error {
  color: #ff4444;
  margin: 10px 0;
}

.success {
  color: #44ff44;
  margin: 10px 0;
}

button {
  background: #ffd700;
  color: #1a1a1a;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
} 
