.admin-nav {
  width: 250px;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(255, 215, 0, 0.1);
  padding: 2rem;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
}

.nav-header {
  text-align: center;
  margin-bottom: 3rem;
}

.nav-header h1 {
  font-size: 1.5rem;
  background: linear-gradient(to right, #fff, #ffd700);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}

.nav-subtitle {
  color: #9ca3af;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.nav-links {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
}

.nav-link {
  background: none;
  border: none;
  color: white;
  padding: 1rem;
  text-align: left;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.nav-link:hover {
  background: rgba(255, 215, 0, 0.1);
}

.nav-link.active {
  background: #ffd700;
  color: #111827;
  font-weight: 600;
}

.logout-button {
  margin-top: auto;
  background: rgba(255, 0, 0, 0.1);
  color: #ff4444;
  border: 1px solid rgba(255, 0, 0, 0.3);
  padding: 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.logout-button:hover {
  background: rgba(255, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .admin-nav {
    width: 100%;
    height: auto;
    position: relative;
    padding: 1rem;
  }

  .nav-links {
    flex-direction: row;
    overflow-x: auto;
    padding-bottom: 0.5rem;
  }

  .nav-link {
    white-space: nowrap;
  }
} 