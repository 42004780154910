.challenge-list {
  margin: 2rem 0;
  padding: 1rem;
}

.challenge-list h2 {
  color: #ffd700;
  margin-bottom: 1.5rem;
}

.challenge-list h2.expired-header {
  color: #888;
}

.challenges-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.challenge-card {
  background: #1a1a1a;
  border: 1px solid #333;
  border-radius: 8px;
  padding: 1.5rem;
  transition: transform 0.2s ease;
}

.challenge-card:hover {
  transform: translateY(-2px);
}

.challenge-card h3 {
  color: #ffd700;
  margin: 0 0 1rem 0;
}

.scheduled-date {
  color: #888;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.description {
  color: #fff;
  margin-bottom: 1rem;
  line-height: 1.4;
}

.badge-previews {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.badge-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.badge-preview img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.badge-preview span {
  font-size: 0.8rem;
  color: #888;
}

.no-challenges {
  text-align: center;
  color: #888;
  padding: 2rem;
  background: #1a1a1a;
  border-radius: 8px;
}

.challenge-card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.delete-button {
  background: none;
  border: none;
  color: #ff4444;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0 0.5rem;
  line-height: 1;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.delete-button:hover {
  opacity: 1;
}

.challenge-card-header h3 {
  margin: 0;
  flex: 1;
  padding-right: 1rem;
}

.challenge-image-container {
  margin: 1rem 0;
  text-align: center;
}

.challenge-preview-image {
  max-width: 200px;
  max-height: 200px;
  border-radius: 8px;
  object-fit: cover;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.expired-header {
  margin-top: 3rem;
  color: #888;
}
 