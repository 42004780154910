html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  background: #0a0a0a;
  overflow-x: hidden;
}

.badge-vault-container {
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient(135deg, #0a0a0a 0%, #1a1a1a 100%);
  color: white;
  padding: 2rem;
  position: relative;
  margin: 0;
  overflow-x: hidden;
}

.badge-vault-content {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.vault-header {
  text-align: center;
  margin-bottom: 3rem;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  backdrop-filter: blur(10px);
}

.vault-title {
  font-size: 3rem;
  font-weight: 800;
  background: linear-gradient(45deg, #ffd700, #ffffff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 0.5rem;
}

.vault-subtitle {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin: 32px 0;
}

.stat-item {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 24px 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.stat-item:hover {
  transform: translateY(-5px);
}

.stat-value {
  font-size: 24px;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  gap: 8px;
}

.stat-label {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.badges-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
}

.badge-card {
  background: rgba(17, 24, 39, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  overflow: hidden;
}

.badge-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.1);
}

.badge-image {
  width: 120px;
  height: 120px;
  margin: 0 auto 1rem;
  filter: drop-shadow(0 0 10px rgba(255, 128, 0, 0.3));
}

.badge-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: white;
  margin-bottom: 0.5rem;
}

.badge-date {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 0.5rem;
}

.badge-difficulty {
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  display: inline-block;
}

.difficulty-gold {
  background: rgba(255, 215, 0, 0.2);
  color: #ffd700;
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
}

.difficulty-silver {
  background: rgba(192, 192, 192, 0.2);
  color: #c0c0c0;
  text-shadow: 0 0 10px rgba(192, 192, 192, 0.5);
}

.difficulty-bronze {
  background: rgba(205, 127, 50, 0.2);
  color: #cd7f32;
  text-shadow: 0 0 10px rgba(205, 127, 50, 0.5);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes gradientShift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@media (max-width: 768px) {
  .stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .badges-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
  }
  
  .badge-image {
    width: 80px;
    height: 80px;
  }
  
  .vault-title {
    font-size: 2rem;
  }
} 

.share-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  justify-content: center;
}

.share-button {
  padding: 0.8rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.2s;
}

.twitter { background: #1DA1F2; color: white; }
.facebook { background: #4267B2; color: white; }
.share { background: linear-gradient(45deg, #2196F3, #00BCD4); }

.share-button:hover {
  transform: translateY(-2px);
  filter: brightness(110%);
}

.glow-effect {
  position: relative;
}

.glow-effect::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, 
    rgba(255, 215, 0, 0.2),
    rgba(255, 255, 255, 0.1),
    rgba(255, 215, 0, 0.2)
  );
  border-radius: 16px;
  z-index: 0;
  animation: glowAnimation 3s ease-in-out infinite;
}

@keyframes glowAnimation {
  0%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
}

.badge-share {
  position: relative;
  z-index: 1;
  margin-top: 1rem;
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.3s ease;
}

.badge-card:hover .badge-share {
  opacity: 1;
  transform: translateY(0);
}

.badge-share-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  justify-content: center;
}

.badge-share-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.badge-content {
  position: relative;
  z-index: 1;
  background: rgba(17, 24, 39, 0.9);
  border-radius: 12px;
  padding: 1rem;
}

.loading-container {
  min-height: 100vh;
  background: #111827;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.animated-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(-45deg, #0a0a0a, #1a1a1a, #2a2a2a, #1a1a1a);
  background-size: 400% 400%;
  animation: gradientShift 15s ease infinite;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, transparent 0%, rgba(0, 0, 0, 0.8) 100%);
}

.join-momentum-button {
  display: inline-block;
  margin-top: 1.5rem;
  padding: 1rem 2rem;
  background: linear-gradient(45deg, #ffd700, #ff8c00);
  color: white;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(255, 215, 0, 0.2);
}

.join-momentum-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(255, 215, 0, 0.3);
  background: linear-gradient(45deg, #ffd700, #ffa500);
}

.join-momentum-button:active {
  transform: translateY(0);
}

.badge-card[data-difficulty="gold"]::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, 
    rgba(255, 215, 0, 0.1),
    rgba(255, 215, 0, 0.2),
    rgba(255, 215, 0, 0.1)
  );
  border-radius: 16px;
  z-index: 0;
  animation: borderGlow 3s ease-in-out infinite;
}

.badge-card[data-difficulty="silver"]::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, 
    rgba(192, 192, 192, 0.1),
    rgba(192, 192, 192, 0.2),
    rgba(192, 192, 192, 0.1)
  );
  border-radius: 16px;
  z-index: 0;
  animation: borderGlow 3s ease-in-out infinite;
}

.badge-card[data-difficulty="bronze"]::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, 
    rgba(205, 127, 50, 0.1),
    rgba(205, 127, 50, 0.2),
    rgba(205, 127, 50, 0.1)
  );
  border-radius: 16px;
  z-index: 0;
  animation: borderGlow 3s ease-in-out infinite;
}

@keyframes borderGlow {
  0%, 100% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.01);
  }
}

@media (max-width: 480px) {
  .stats-grid {
    gap: 12px;
  }
  
  .stat-item {
    padding: 16px 12px;
  }
  
  .stat-value {
    font-size: 20px;
  }
}

.loading-state,
.error-state {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  margin: 2rem auto;
  max-width: 600px;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top-color: #ffd700;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

.error-state h2 {
  color: #ff4d4d;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.error-state p {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 2rem;
  line-height: 1.6;
}

.primary-button {
  background: #4f46e5;
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-block;
}

.primary-button:hover {
  transform: translateY(-2px);
  background: #4338ca;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

@keyframes spin {
  to { transform: rotate(360deg); }
}
