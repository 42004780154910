.badge-manager {
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  margin: 2rem 0;
}

.badge-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.create-button {
  background: #ffd700;
  color: #111827;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.create-button:hover {
  background: #fff;
  transform: translateY(-2px);
}

.badge-form {
  background: rgba(0, 0, 0, 0.2);
  padding: 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  border: 1px solid rgba(255, 215, 0, 0.1);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #ffd700;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  background: rgba(0, 0, 0, 0.2);
  border: 2px solid rgba(255, 215, 0, 0.3);
  border-radius: 6px;
  color: white;
  font-size: 1rem;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #ffd700;
}

.submit-button {
  background: #ffd700;
  color: #111827;
  padding: 1rem 2rem;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.submit-button:hover {
  background: #fff;
  transform: translateY(-2px);
}

.badges-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.badge-card {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 215, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.badge-card:hover {
  transform: translateY(-5px);
  border-color: rgba(255, 215, 0, 0.3);
}

.badge-image {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
}

.badge-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.badge-placeholder {
  font-size: 4rem;
}

.badge-info {
  padding: 1.5rem;
}

.badge-info h3 {
  color: #ffd700;
  margin: 0 0 0.5rem 0;
}

.description {
  color: #9ca3af;
  margin-bottom: 1rem;
}

.requirements {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 215, 0, 0.1);
}

.requirements h4 {
  color: #ffd700;
  margin: 0 0 0.5rem 0;
  font-size: 0.9rem;
}

.badge-meta {
  margin-top: 1rem;
  font-size: 0.8rem;
  color: #9ca3af;
}

.file-input {
  width: 100%;
  padding: 0.75rem;
  background: rgba(0, 0, 0, 0.2);
  border: 2px solid rgba(255, 215, 0, 0.3);
  border-radius: 6px;
  color: white;
  cursor: pointer;
}

.image-preview {
  margin-top: 1rem;
  max-width: 200px;
  border-radius: 8px;
  overflow: hidden;
}

.image-preview img {
  width: 100%;
  height: auto;
  display: block;
} 