* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: #0a0a0a;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  background-size: 400% 400%;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  position: relative;
  background: linear-gradient(
    135deg,
    #0a0a0a 0%,
    #1a1a2e 50%,
    #0a0a0a 100%
  );
  animation: gradientBG 15s ease infinite;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Mobile-first responsive design */
@media (max-width: 768px) {
  html {
    font-size: 14px; /* Slightly smaller base font size on mobile */
  }

  .App {
    padding: 0; /* Remove any default padding */
  }
}

/* Hide scrollbar but keep functionality */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #111827;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 215, 0, 0.3);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 215, 0, 0.5);
}

/* Improve input and button styles on mobile */
input,
button,
textarea,
select {
  font-size: 16px; /* Prevent zoom on focus in iOS */
  -webkit-appearance: none; /* Remove default styling */
  border-radius: 8px;
}

/* Improve tap target sizes on mobile */
button,
a {
  min-height: 44px;
  min-width: 44px;
  padding: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Prevent content from being hidden under mobile browser chrome */
@supports (-webkit-touch-callout: none) {
  .App {
    min-height: -webkit-fill-available;
  }
}

.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}

.hero-text {
  font-size: 1.75rem;
  font-weight: 700;
  color: #2d2d2d;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  letter-spacing: -0.5px;
}

.challenge-text {
  font-size: 1.25rem;
  color: #666666;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.cta-button {
  background-color: #404040;
  color: white;
  padding: 14px 28px;
  border-radius: 12px;
  border: none;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.cta-button:hover {
  background-color: #333333;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.cta-button:active {
  transform: translateY(0);
}

@media (min-width: 768px) {
  .App {
    padding: 24px;
  }
  
  .container {
    max-width: 720px;
  }
  
  .hero-text {
    font-size: 2.25rem;
  }
}

.admin-layout {
  display: flex;
  min-height: 100vh;
  background: #111827;
}

.admin-content {
  flex: 1;
  margin-left: 250px;
  padding: 2rem;
}

@media (max-width: 768px) {
  .admin-layout {
    flex-direction: column;
  }
  
  .admin-content {
    margin-left: 0;
    padding: 1rem;
  }
} 