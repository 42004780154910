.user-management {
  padding: 20px;
  background: #1a1a1a;
  border-radius: 8px;
  margin: 20px;
}

.user-management h2 {
  color: #ffd700;
  margin-bottom: 20px;
}

.loading-state {
  padding: 20px;
  text-align: center;
  color: #ccc;
}

.error-state, .error-message {
  padding: 10px;
  color: #ff4444;
  background: rgba(255, 68, 68, 0.1);
  border-radius: 4px;
  margin: 10px 0;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.user-table th,
.user-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #333;
}

.user-table th {
  background: #2a2a2a;
  color: #ffd700;
}

.user-table tr:hover {
  background: #2a2a2a;
}

.actions {
  display: flex;
  gap: 10px;
}

.actions select {
  padding: 5px;
  background: #2a2a2a;
  border: 1px solid #444;
  border-radius: 4px;
  color: white;
}

.delete-button {
  padding: 5px 10px;
  background: #ff4444;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.delete-button:hover {
  background: #ff6666;
}

.delete-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
} 